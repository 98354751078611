import { useWindowWidth } from '@react-hook/window-size';
import cx from 'classnames';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';

import useStickyProgress from '../../../utils/use-sticky-progress';

import styles from './Increase.module.scss';

import { forwardRef, useRef } from 'react';
import { interpolate } from '../../../utils';

const OFFSET_BLOCK1 = 200;
const OFFSET_BLOCK2 = 300;
const OFFSET_BLOCK3 = 400;
const OFFSET_BLOCK4 = 500;
const BLOCK_HEIGHT = 306;

const MOBILE_BLOCK_OFFSET = 80;
const MOBILE_BLOCK_HEIGHT = 360;

const Increase = ({ className }: { className?: string }) => {
  const { t } = useI18next();
  const block1FloatRef = useRef<HTMLDivElement>(null);
  const block2FloatRef = useRef<HTMLDivElement>(null);
  const block3FloatRef = useRef<HTMLDivElement>(null);
  const block4FloatRef = useRef<HTMLDivElement>(null);
  const block1StaticRef = useRef<HTMLDivElement>(null);
  const block2StaticRef = useRef<HTMLDivElement>(null);
  const block3StaticRef = useRef<HTMLDivElement>(null);
  const block4StaticRef = useRef<HTMLDivElement>(null);
  const mobileStaticRef = useRef<HTMLDivElement>(null);

  const winWidth = useWindowWidth();
  const isMobile = winWidth < 768;

  const { ref } = useStickyProgress({
    effectDeps: [isMobile],
    effect: ({ pixelBetween }) => {
      if (isMobile) {
        if (mobileStaticRef.current) {
          const waitOffset =
            4 * MOBILE_BLOCK_OFFSET +
            3 * (MOBILE_BLOCK_HEIGHT + MOBILE_BLOCK_OFFSET) +
            MOBILE_BLOCK_HEIGHT / 2;

          mobileStaticRef.current.style.opacity = `${interpolate(
            Math.max(pixelBetween - waitOffset, 0),
            0,
            MOBILE_BLOCK_HEIGHT,
            0,
            1
          )}`;
        }
        if (block1FloatRef.current) {
          const waitOffset =
            4 * MOBILE_BLOCK_OFFSET +
            3 * (MOBILE_BLOCK_HEIGHT + MOBILE_BLOCK_OFFSET);
          const moveY =
            pixelBetween < 3 * MOBILE_BLOCK_OFFSET
              ? (pixelBetween * 0) / 3
              : pixelBetween < waitOffset
              ? 0 * MOBILE_BLOCK_OFFSET
              : 0 * MOBILE_BLOCK_OFFSET + pixelBetween - waitOffset;

          block1FloatRef.current.style.transform = `translateY(${-Math.max(
            moveY,
            0
          )}px)`;
        }
        if (block2FloatRef.current) {
          const waitOffset =
            4 * MOBILE_BLOCK_OFFSET +
            2 * (MOBILE_BLOCK_HEIGHT + MOBILE_BLOCK_OFFSET);
          const moveY =
            pixelBetween < 3 * MOBILE_BLOCK_OFFSET
              ? (pixelBetween * 1) / 3
              : pixelBetween < waitOffset
              ? 1 * MOBILE_BLOCK_OFFSET
              : 1 * MOBILE_BLOCK_OFFSET + pixelBetween - waitOffset;

          block2FloatRef.current.style.transform = `translateY(${-Math.max(
            moveY,
            0
          )}px)`;
        }
        if (block3FloatRef.current) {
          const waitOffset =
            4 * MOBILE_BLOCK_OFFSET +
            1 * (MOBILE_BLOCK_HEIGHT + MOBILE_BLOCK_OFFSET);
          const moveY =
            pixelBetween < 3 * MOBILE_BLOCK_OFFSET
              ? (pixelBetween * 2) / 3
              : pixelBetween < waitOffset
              ? 2 * MOBILE_BLOCK_OFFSET
              : 2 * MOBILE_BLOCK_OFFSET + pixelBetween - waitOffset;

          block3FloatRef.current.style.transform = `translateY(${-Math.max(
            moveY,
            0
          )}px)`;
        }
        if (block4FloatRef.current) {
          const waitOffset =
            4 * MOBILE_BLOCK_OFFSET +
            0 * (MOBILE_BLOCK_HEIGHT + MOBILE_BLOCK_OFFSET);
          const moveY =
            pixelBetween < 3 * MOBILE_BLOCK_OFFSET
              ? (pixelBetween * 3) / 3
              : pixelBetween < waitOffset
              ? 3 * MOBILE_BLOCK_OFFSET
              : 3 * MOBILE_BLOCK_OFFSET + pixelBetween - waitOffset;

          block4FloatRef.current.style.transform = `translateY(${-Math.max(
            moveY,
            0
          )}px)`;
        }
      } else {
        if (block1FloatRef.current) {
          block1FloatRef.current.style.transform = `translateY(${-Math.max(
            pixelBetween - OFFSET_BLOCK1,
            0
          )}px)`;
        }
        if (block2FloatRef.current) {
          block2FloatRef.current.style.transform = `translateY(${-Math.max(
            pixelBetween - OFFSET_BLOCK2,
            0
          )}px)`;
        }
        if (block3FloatRef.current) {
          block3FloatRef.current.style.transform = `translateY(${-Math.max(
            pixelBetween - OFFSET_BLOCK3,
            0
          )}px)`;
        }
        if (block4FloatRef.current) {
          block4FloatRef.current.style.transform = `translateY(${-Math.max(
            pixelBetween - OFFSET_BLOCK4,
            0
          )}px)`;
        }

        if (block1StaticRef.current) {
          block1StaticRef.current.style.opacity = `${interpolate(
            Math.max(pixelBetween - OFFSET_BLOCK1, 0),
            0,
            BLOCK_HEIGHT * 2,
            0,
            1
          )}`;
        }
        if (block2StaticRef.current) {
          block2StaticRef.current.style.opacity = `${interpolate(
            Math.max(pixelBetween - OFFSET_BLOCK2, 0),
            0,
            BLOCK_HEIGHT * 2,
            0,
            1
          )}`;
        }
        if (block3StaticRef.current) {
          block3StaticRef.current.style.opacity = `${interpolate(
            Math.max(pixelBetween - OFFSET_BLOCK3, 0),
            0,
            BLOCK_HEIGHT * 2,
            0,
            1
          )}`;
        }
        if (block4StaticRef.current) {
          block4StaticRef.current.style.opacity = `${interpolate(
            Math.max(pixelBetween - OFFSET_BLOCK4, 0),
            0,
            BLOCK_HEIGHT * 2,
            0,
            1
          )}`;
        }
      }
    },
  });

  const StaticBlock1 = forwardRef<HTMLDivElement, { className?: string }>(
    ({ className }, ref) => {
      return (
        <div className={cx(styles.static, className)} ref={ref}>
          <b>
            <span className={styles.sign}>+</span>
            <span className={styles.value}>45%</span>
          </b>
          <span className={styles.caption}>
            <Trans
              i18nKey="merchant-2-increase-purchase-merchant-cat"
              components={{ br: <br /> }}
            />
          </span>
        </div>
      );
    }
  );

  const StaticBlock2 = forwardRef<HTMLDivElement, { className?: string }>(
    ({ className }, ref) => {
      return (
        <div className={cx(styles.static, className)} ref={ref}>
          <b>
            <span className={styles.sign}>+</span>
            <span className={styles.value}>10%</span>
          </b>
          <span className={styles.caption}>
            <Trans
              i18nKey="merchant-2-increase-grow-audience-merchant-cat"
              components={{ br: <br /> }}
            />
          </span>
        </div>
      );
    }
  );

  const StaticBlock3 = forwardRef<HTMLDivElement, { className?: string }>(
    ({ className }, ref) => {
      return (
        <div className={cx(styles.static, className)} ref={ref}>
          <b>
            <span className={styles.sign}>+</span>
            <span className={styles.value}>30%</span>
          </b>
          <span className={styles.caption}>
            <Trans
              i18nKey="merchant-2-increase-ahead-the-curve-merchant-cat"
              components={{ br: <br /> }}
            />
          </span>
        </div>
      );
    }
  );

  const StaticBlock4 = forwardRef<HTMLDivElement, { className?: string }>(
    ({ className }, ref) => {
      return (
        <div className={cx(styles.static, className)} ref={ref}>
          <b>
            <span className={styles.sign}>+</span>
            <span className={styles.value}>10%</span>
          </b>
          <span className={styles.caption}>
            <Trans
              i18nKey="merchant-2-increase-improve-conversation-rate-merchant-cat"
              components={{ br: <br /> }}
            />
          </span>
        </div>
      );
    }
  );

  return (
    <div ref={ref} className={styles.parallaxContainer}>
      <div className={styles.contentContainer}>
        <section
          id="increase"
          className={cx(className, styles.increaseContainer)}
        >
          <div className={styles.increase}>
            <div className={styles.box}>
              <div className={styles.content}>
                <div className={styles.titles}>
                  <h2>
                    <Trans
                      i18nKey="merchant-2-increase-title"
                      components={{ br: <br /> }}
                    />
                  </h2>
                  <h4>
                    <Trans
                      i18nKey="merchant-2-increase-subtitle"
                      components={{ br: <br /> }}
                    />
                  </h4>
                </div>

                <div className={styles.blocks}>
                  <div
                    className={cx(styles.mobileStatic)}
                    ref={mobileStaticRef}
                  >
                    <StaticBlock1 className={styles.block1} />
                    <StaticBlock2 className={styles.block2} />
                    <StaticBlock3 className={styles.block3} />
                    <StaticBlock4 className={styles.block4} />
                  </div>
                  <div className={cx(styles.block, styles.block1)}>
                    <div className={styles.float} ref={block1FloatRef}>
                      <span className={styles.caption}>
                        {t('merchant-2-increase-purchase-prices')}
                      </span>
                      <b>
                        <span className={styles.sign}>+</span>
                        <span className={styles.value}>40%</span>
                      </b>
                      <span className={styles.desc}>
                        {t('merchant-2-increase-purchase-prices-desc')}
                      </span>
                    </div>
                    <StaticBlock1 ref={block1StaticRef} />
                  </div>

                  <div className={cx(styles.block, styles.block2)}>
                    <div className={styles.float} ref={block2FloatRef}>
                      <span className={styles.caption}>
                        {t('merchant-2-increase-grow-audience')}
                      </span>
                      <b>
                        <span className={styles.sign}>+</span>
                        <span className={styles.value}>3,028%</span>
                      </b>
                      <span className={styles.desc}>
                        <Trans
                          i18nKey="merchant-2-increase-grow-audience-desc"
                          components={{ br: <br /> }}
                        />
                      </span>
                    </div>
                    <StaticBlock2 ref={block2StaticRef} />
                  </div>

                  <div className={cx(styles.block, styles.block3)}>
                    <div className={styles.float} ref={block3FloatRef}>
                      <span className={styles.caption}>
                        {t('merchant-2-increase-ahead-the-curve')}
                      </span>
                      <b>
                        <span className={styles.sign}>+</span>
                        <span className={styles.value}>25%</span>
                      </b>
                      <span className={styles.desc}>
                        <Trans
                          i18nKey="merchant-2-increase-ahead-the-curve-desc"
                          components={{ br: <br /> }}
                        />
                      </span>
                    </div>
                    <StaticBlock3 ref={block3StaticRef} />
                  </div>

                  <div className={cx(styles.block, styles.block4)}>
                    <div className={styles.float} ref={block4FloatRef}>
                      <span className={styles.caption}>
                        {t('merchant-2-increase-improve-conversation-rate')}
                      </span>
                      <b>
                        <span className={styles.sign}>+</span>
                        <span className={styles.value}>10%</span>
                      </b>
                      <span className={styles.desc}>
                        <Trans
                          i18nKey="merchant-2-increase-improve-conversation-rate-desc"
                          components={{ br: <br /> }}
                        />
                      </span>
                    </div>
                    <StaticBlock4 ref={block4StaticRef} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Increase;
