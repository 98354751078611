import { useI18next } from 'gatsby-plugin-react-i18next';
import ContentBNPLCompare from '../../Modals/ContentBNPLCompare';
import SideModal from '../../SideModal/SideModal';
import ContentJapanEC from './ContentJapanEC';
import ContentSecurity from './ContentSecurity';
import styles from './ModalBNPL.module.scss';

export type MODAL_TYPE = 'japan-ec' | 'bnpl-compare' | 'security';
type PROP = {
  type?: string;
};

const title = (type: MODAL_TYPE) => {
  switch (type) {
    case 'bnpl-compare':
      return 'compare-title';
    case 'security':
      return 'security-modal-title';
    default:
      return 'japan-ec-market';
  }
};

const Content = ({ type }: PROP) => {
  switch (type) {
    case 'bnpl-compare':
      return <ContentBNPLCompare />;
    case 'security':
      return <ContentSecurity />;
    default:
      return <ContentJapanEC />;
  }
};

const ModalBNPL = ({
  active,
  type,
  close,
}: PROP & {
  active: boolean;
  close: () => void;
}) => {
  const { t } = useI18next();

  return (
    <SideModal
      title={t(title(type as MODAL_TYPE))}
      active={active}
      close={close}
      containerClassName={
        type === 'bnpl-compare' ? styles.bigTableContainer : ''
      }
    >
      <Content type={type as MODAL_TYPE} />
    </SideModal>
  );
};

export default ModalBNPL;
