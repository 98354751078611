import cx from 'classnames';
import { useI18next } from 'gatsby-plugin-react-i18next';
import styles from './Partners.module.scss';

import srcPartnerAdobe from '../../../assets/merchant-2/partners/partner-adobe-experience-cloud-logo.svg';
import srcPartnerBbf from '../../../assets/merchant-2/partners/partner-bbf.svg';
import srcPartnerEbisumart from '../../../assets/merchant-2/partners/partner-ebisumart.svg';
import srcPartnerEcCube from '../../../assets/merchant-2/partners/partner-ec-cube.svg';
import srcPartnerEcbeing from '../../../assets/merchant-2/partners/partner-ecbeing.svg';
import srcPartnerFace from '../../../assets/merchant-2/partners/partner-face.svg';
import srcPartnerFujisoft from '../../../assets/merchant-2/partners/partner-fujisoft.svg';
import srcPartnerGr4vy from '../../../assets/merchant-2/partners/partner-gr4vy.svg';
import srcPartnerSalesforce from '../../../assets/merchant-2/partners/partner-salesforce.svg';
import srcPartnerSap from '../../../assets/merchant-2/partners/partner-sap.svg';
import srcPartnerScsk from '../../../assets/merchant-2/partners/partner-scsk.svg';
import srcPartnerShopify from '../../../assets/merchant-2/partners/partner-shopify.svg';

const PARTNERS = [
  {
    id: 'bbf',
    logo: srcPartnerBbf,
    name: 'BBF',
    url: 'https://www.bb-f.co.jp/',
  },
  {
    id: 'ebisumart',
    logo: srcPartnerEbisumart,
    name: 'ebisumart',
    url: 'https://www.ebisumart.com/',
  },
  {
    id: 'ec-cube',
    logo: srcPartnerEcCube,
    name: 'EC-CUBE',
    url: 'https://www.ec-cube.net/',
  },
  {
    id: 'ecbeing',
    logo: srcPartnerEcbeing,
    name: 'ec_being',
    url: 'https://www.ecbeing.net/',
  },
  {
    id: 'fujisoft',
    logo: srcPartnerFujisoft,
    name: '富士ソフト',
    url: 'https://www.fsi.co.jp/',
  },
  {
    id: 'gr4vy',
    logo: srcPartnerGr4vy,
    name: 'Gr4vy',
    url: 'https://gr4vy.com/',
  },
  {
    id: 'adobe',
    logo: srcPartnerAdobe,
    name: 'Adobe',
    url: 'https://business.adobe.com/jp/products/magento/magento-commerce.html',
  },
  {
    id: 'salesforce',
    logo: srcPartnerSalesforce,
    name: 'Salesforce Commerce Cloud',
    url: 'https://www.salesforce.com/jp/',
  },
  {
    id: 'sap',
    logo: srcPartnerSap,
    name: 'SAP Commerce Cloud',
    url: 'https://www.sap.com/japan/index.html',
  },
  {
    id: 'face',
    logo: srcPartnerFace,
    name: 'F.ace',
    url: 'https://www.presc.jp/',
  },
  {
    id: 'scsk',
    logo: srcPartnerScsk,
    name: 'SCSK',
    url: 'https://www.scsk.jp/',
  },
  {
    id: 'shopify',
    logo: srcPartnerShopify,
    name: 'Shopify',
    url: 'https://www.shopify.com/jp',
  },
];

const Partners = ({ className }: { className?: string }) => {
  const { t } = useI18next();

  return (
    <section id="partners" className={cx(className, styles.partnersContainer)}>
      <div className={styles.partners}>
        <div className={styles.box}>
          <h2>{t('platform-partner')}</h2>
          <div className={styles.list}>
            {PARTNERS.map((partner) => (
              <a
                key={partner.id}
                href={partner.url}
                target="_blank"
                rel="noopener"
                className={cx(styles.partner)}
              >
                <img alt={partner.name} src={partner.logo} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
