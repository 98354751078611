import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import styles from './ModalBNPL.module.scss';

const ContentJapanEC = () => {
  const { t } = useI18next();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.sec}>
          <h4>{t('japan-ec-market-sec-expansion')}</h4>
          <div className={styles.secContent}>
            <div className={styles.expansionItem}>
              <span className={styles.label}>
                {t('japan-ec-market-sec-expansion-1')}
              </span>
              <b>
                <span className={styles.sign}>+</span>
                <span className={styles.value}>
                  {t('japan-ec-market-sec-expansion-1-value')}
                </span>
              </b>
            </div>
            <div className={styles.expansionItem}>
              <span className={styles.label}>
                {t('japan-ec-market-sec-expansion-2')}
              </span>
              <b>
                <span className={styles.sign}>+</span>
                <span className={styles.value}>
                  {t('japan-ec-market-sec-expansion-2-value')}
                </span>
              </b>
            </div>
            <div className={styles.expansionItem}>
              <span className={styles.label}>
                <Trans
                  i18nKey="japan-ec-market-sec-expansion-3"
                  components={{ br: <br /> }}
                />
              </span>
              <b>
                <span className={styles.value}>
                  {t('japan-ec-market-sec-expansion-3-value')}
                </span>
              </b>
            </div>
            <div className={styles.expansionItem}>
              <span className={styles.label}>
                <Trans
                  i18nKey="japan-ec-market-sec-expansion-4"
                  components={{ br: <br /> }}
                />
              </span>
              <b>
                <span className={styles.sign}>+</span>
                <span className={styles.value}>
                  {t('japan-ec-market-sec-expansion-4-value')}
                </span>
              </b>
            </div>
          </div>
        </div>
        <div className={styles.sec}>
          <h4>{t('japan-ec-market-sec-cart-drop-off')}</h4>
          <div className={styles.secContent}>
            <table className={styles.cartDropTable}>
              <thead>
                <tr>
                  <th className={styles.colJapan}>{t('japan')}</th>
                  <th className={styles.colUs}>{t('us')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th colSpan={2}>
                    {t('japan-ec-market-sec-cart-drop-off-1')}
                  </th>
                </tr>
                <tr>
                  <td className={styles.colJapan}>
                    <b>89%</b>
                  </td>
                  <td className={styles.colUs}>
                    <b>77%</b>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <th colSpan={2}>
                    {t('japan-ec-market-sec-cart-drop-off-2')}
                  </th>
                </tr>
                <tr>
                  <td className={styles.colJapan}>
                    <b>11%</b>
                  </td>
                  <td className={styles.colUs}>
                    <b>23%</b>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td className={styles.colUs} colSpan={2}>
                    <b>2x</b>
                    <span className={styles.note}>
                      <Trans
                        i18nKey="japan-ec-market-sec-cart-drop-off-3"
                        components={{ br: <br /> }}
                      />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles.sec}>
          <h4>{t('japan-ec-market-sec-cashless')}</h4>
          <div className={styles.secContent}>
            <div className={styles.cashlessItem}>
              <span className={styles.label}>2017</span>
              <b>21%</b>
            </div>
            <div className={styles.cashlessItem}>
              <span className={styles.label}>2021</span>
              <b>33%</b>
            </div>
            <div className={styles.cashlessItem}>
              <span className={styles.label}>2025*</span>
              <b>40%</b>
            </div>
            <br />
            <div className={styles.note}>
              {t('japan-ec-market-2025-target')}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentJapanEC;
