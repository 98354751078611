import cx from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link, Trans, useI18next } from 'gatsby-plugin-react-i18next';
import useImageQuery from '../../../utils/use-image-query';
import styles from './CaseStudy.module.scss';

const CaseStudy = ({ className }: { className?: string }) => {
  const { t } = useI18next();
  const { srcCaseStudy1 } = useImageQuery();

  return (
    <section
      id="case-studies"
      className={cx(className, styles.caseStudyContainer)}
    >
      <div className={styles.caseStudy}>
        <div className={styles.box}>
          <div className={styles.header}>
            <h2>{t('case-studies')}</h2>
            <Link to="/newsroom/#case-studies">{t('view-all')}</Link>
          </div>
          <a
            href="https://assets.smartpay.co/case-study/smartpay-tricera.pdf"
            className={styles.caseBox}
            target="_blank"
          >
            <div className={styles.content}>
              <div className={styles.merchant}>TRICERA</div>
              <h3>
                <Trans
                  i18nKey="merchant-2-case-study-case-1"
                  components={{ br: <br /> }}
                />
              </h3>
              {/* <a className={styles.link}>{t('merchant-2-find-out-more')}</a> */}
            </div>
            <GatsbyImage
              alt=""
              image={srcCaseStudy1.childImageSharp.gatsbyImageData}
              className={styles.figure}
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default CaseStudy;
