import { useI18next } from 'gatsby-plugin-react-i18next';

import styles from './ModalBNPL.module.scss';

import srcIcon1 from '../../../assets/merchant-2/security-icon-1-transfer.svg';
import srcIcon2 from '../../../assets/merchant-2/security-icon-2-shield.svg';
import srcIcon3 from '../../../assets/merchant-2/security-icon-3-eye.svg';
import srcIcon4 from '../../../assets/merchant-2/security-icon-4-donate.svg';

const ContentSecurity = () => {
  const { t } = useI18next();

  return (
    <>
      <p className={styles.normal}>
        <img
          src={srcIcon1}
          className={styles.securityIcon}
          alt=""
          loading="lazy"
        />
        {t('security-feat-1-desc')}
      </p>
      <p className={styles.normal}>
        <img
          src={srcIcon2}
          className={styles.securityIcon}
          alt=""
          loading="lazy"
        />
        {t('security-feat-2-desc')}
      </p>
      <p className={styles.normal}>
        <img
          src={srcIcon3}
          className={styles.securityIcon}
          alt=""
          loading="lazy"
        />
        {t('security-feat-3-desc')}
      </p>
      <p className={styles.normal}>
        <img
          src={srcIcon4}
          className={styles.securityIcon}
          alt=""
          loading="lazy"
        />
        {t('security-feat-4-desc')}
      </p>
    </>
  );
};

export default ContentSecurity;
