import cx from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useI18next } from 'gatsby-plugin-react-i18next';
import useImageQuery from '../../../utils/use-image-query';
import styles from './Awards.module.scss';

const Awards = ({ className }: { className?: string }) => {
  const { t } = useI18next();
  const { srcAwardJfia, srcAwardGfmr, srcAwardFinext } = useImageQuery();

  return (
    <section id="awards" className={cx(className, styles.awardsContainer)}>
      <div className={styles.awards}>
        <div className={styles.box}>
          <a
            href="https://jfia.tokyo/2023-jp/"
            target="_blank"
            rel="noopener"
            className={cx(styles.award, styles.coloredBg)}
          >
            <GatsbyImage
              alt="Japan Financial Innovation Award 2023"
              image={srcAwardJfia.childImageSharp.gatsbyImageData}
            />
          </a>
          <a
            href="https://www.gfmreview.com/press-release/smartpay-japan-wins-the-fastest-growing-fintech-asia-2023-award-by-global-financial-market-review"
            target="_blank"
            rel="noopener"
            className={styles.award}
          >
            <GatsbyImage
              alt="Global Financial Market Review, Fatest Growing Fintech Asia 2023"
              image={srcAwardGfmr.childImageSharp.gatsbyImageData}
            />
          </a>
          <a
            href="https://www.finextcon.com/smartpay-takes-home-excellence-in-finance-companies-award-at-finext-awards-in-dubai/"
            target="_blank"
            rel="noopener"
            className={styles.award}
          >
            <GatsbyImage
              alt="Finext Awards, Execellence in Finance Companies, Payment Gateways Awards"
              image={srcAwardFinext.childImageSharp.gatsbyImageData}
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Awards;
