import { useWindowWidth } from '@react-hook/window-size';
import cx from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { useEffect, useRef } from 'react';
import phoneDecoIconSrc from '../../../assets/merchant-2/about-bnpl-screen-deco-icon.svg';
import useImageQuery from '../../../utils/use-image-query';
import { interpolate } from '../../../utils';
import useStickyProgress from '../../../utils/use-sticky-progress';
import styles from './AboutBNPL.module.scss';

const IconPlus = ({ className }: { className?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12 6L12 18"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 12L18 12"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const AboutBNPL = ({
  className,
  showModalJapanEC,
  showModalBNPLCompare,
  showModalSecurity,
}: {
  className?: string;
  showModalJapanEC: () => void;
  showModalBNPLCompare: () => void;
  showModalSecurity: () => void;
}) => {
  const winWidth = useWindowWidth();
  const isMobile = winWidth < 1023;

  const { t, i18n } = useI18next();
  const blocksRef = useRef<HTMLImageElement>(null);
  const screenRef = useRef<HTMLImageElement>(null);
  const screenDecoItemRef = useRef<HTMLImageElement>(null);
  const screenDecoIconRef = useRef<HTMLImageElement>(null);

  const { srcAboutBNPLPhoneEn, srcAboutBNPLPhoneJa, srcAboutBNPLDecoItem } =
    useImageQuery();

  const phoneSrc =
    i18n.language === 'en' ? srcAboutBNPLPhoneEn : srcAboutBNPLPhoneJa;

  const { ref } = useStickyProgress({
    disabled: isMobile,
    effect: ({ pixelBetween, pixelOut, progressBetween }) => {
      if (screenRef.current) {
        screenRef.current.style.transform = `translateX(-50%) translateY(${-(
          pixelBetween - pixelOut
        )}px)`;
      }

      if (screenDecoItemRef.current) {
        screenDecoItemRef.current.style.transform = `translateX(${
          isMobile ? '60px' : '90px'
        }) translateY(${-(
          pixelBetween -
          pixelOut +
          -interpolate(progressBetween, 0, 1, 0, 400)
        )}px)`;
      }

      if (screenDecoIconRef.current) {
        screenDecoIconRef.current.style.transform = `translateX(${
          isMobile ? '-126px' : '-184px'
        }) translateY(${-(
          pixelBetween -
          pixelOut +
          -interpolate(progressBetween, 0, 1, 0, 800)
        )}px)`;
      }

      if (blocksRef.current) {
        blocksRef.current.style.opacity = `${interpolate(
          progressBetween,
          0.3,
          0.7,
          0,
          1
        )}`;
      }
    },
  });

  useEffect(() => {
    if (isMobile && blocksRef.current) {
      blocksRef.current.style.opacity = '1';
    }
  }, [isMobile]);

  return (
    <div ref={ref} className={styles.parallaxContainer}>
      <div className={styles.contentContainer}>
        <section
          id="about-bnpl"
          className={cx(className, styles.aboutBnplContainer)}
        >
          <div className={styles.aboutBnpl}>
            <div className={styles.box}>
              <div className={styles.content}>
                <div className={styles.titles}>
                  <h2>
                    <Trans
                      i18nKey="merchant-2-about-bnpl"
                      components={{ br: <br /> }}
                    />
                  </h2>
                  <p>{t('merchant-2-about-bnpl-desc')}</p>
                </div>

                <div ref={blocksRef} className={styles.aboutBlocks}>
                  <a
                    href="#modal-about-japan-ec"
                    className={styles.aboutItem}
                    onClick={showModalJapanEC}
                  >
                    <IconPlus className={styles.iconPlus} />
                    {t('merchant-2-about-bnpl-box-japan-ec')}
                  </a>
                  <a
                    href="#modal-bnpl-compare"
                    className={styles.aboutItem}
                    onClick={showModalBNPLCompare}
                  >
                    <IconPlus className={styles.iconPlus} />
                    {t('merchant-2-about-bnpl-box-compare')}
                  </a>
                  <a
                    href="#modal-security"
                    className={styles.aboutItem}
                    onClick={showModalSecurity}
                  >
                    <IconPlus className={styles.iconPlus} />
                    {
                      <Trans
                        i18nKey="merchant-2-about-bnpl-box-security"
                        components={{ br: <br /> }}
                      />
                    }
                  </a>
                </div>

                <div className={styles.figure} role="presentation">
                  <figure ref={screenRef} className={styles.screen}>
                    <GatsbyImage
                      image={phoneSrc.childImageSharp.gatsbyImageData}
                      alt=""
                    />
                  </figure>
                  <img
                    ref={screenDecoIconRef}
                    src={phoneDecoIconSrc}
                    alt=""
                    className={styles.screenDecoIcon}
                    loading="lazy"
                  />
                  <figure
                    ref={screenDecoItemRef}
                    className={styles.screenDecoItem}
                  >
                    <GatsbyImage
                      image={
                        srcAboutBNPLDecoItem.childImageSharp.gatsbyImageData
                      }
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutBNPL;
