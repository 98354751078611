import { Link, TFunction, Trans } from 'gatsby-plugin-react-i18next';

import styles from './Footer.module.scss';

const MerchantHelp = ({ t }: { t: TFunction }) => {
  return (
    <section className={styles.merchantHelpContainer}>
      <footer className="wrapper">
        <div className={styles.top}>
          <div className={styles.left}>
            <h2>
              <Trans
                i18nKey="footer-merchant-help-title"
                components={{ br: <br /> }}
              />
            </h2>
            <p>{t('footer-merchant-help-desc')}</p>
          </div>
          <div className={styles.right}>
            <Link to="/merchant/register" className={styles.action}>
              {t('footer-merchant-help-action')}
            </Link>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default MerchantHelp;
