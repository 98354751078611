import cx from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { ReactNode } from 'react';
import useImageQuery from '../../../utils/use-image-query';
import Swiper from '../../Swiper/Swiper';
import styles from './Ｗhy.module.scss';

type Item = {
  title: ReactNode | string;
  description: ReactNode | string;
  figure: any;
};

const ＷhyItem = ({ item, className }: { item: Item; className?: string }) => {
  return (
    <div className={cx(styles.slideContainer, className)}>
      <div className={styles.slide}>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <div className={styles.figure} role="presentation">
              <GatsbyImage
                alt=""
                image={item.figure.childImageSharp.gatsbyImageData}
                className={styles.figureImg}
              />
            </div>
            <h4>{item.title}</h4>
            <p>{item.description}</p>
          </div>
        </div>
        <div className={styles.figure} role="presentation">
          <GatsbyImage
            alt=""
            image={item.figure.childImageSharp.gatsbyImageData}
            className={styles.figureImg}
          />
        </div>
      </div>
    </div>
  );
};

const Ｗhy = ({ className }: { className?: string }) => {
  const { t, i18n } = useI18next();
  const {
    srcItem1FigureEn,
    srcItem1FigureJa,
    srcItem2FigureEn,
    srcItem2FigureJa,
    srcItem3FigureEn,
    srcItem3FigureJa,
    srcItem4FigureEn,
    srcItem4FigureJa,
  } = useImageQuery();

  const ITEM_DATA = [
    {
      title: (
        <Trans
          i18nKey="merchant-2-why-1-title"
          components={{ span: <span /> }}
        />
      ),
      description: t('merchant-2-why-1-desc'),
      figure: i18n.language === 'en' ? srcItem1FigureEn : srcItem1FigureJa,
    },
    {
      title: (
        <Trans
          i18nKey="merchant-2-why-2-title"
          components={{ span: <span /> }}
        />
      ),
      description: t('merchant-2-why-2-desc'),
      figure: i18n.language === 'en' ? srcItem2FigureEn : srcItem2FigureJa,
    },
    {
      title: (
        <Trans
          i18nKey="merchant-2-why-3-title"
          components={{ span: <span /> }}
        />
      ),
      description: t('merchant-2-why-3-desc'),
      figure: i18n.language === 'en' ? srcItem3FigureEn : srcItem3FigureJa,
    },
    {
      title: (
        <Trans
          i18nKey="merchant-2-why-4-title"
          components={{ span: <span /> }}
        />
      ),
      description: t('merchant-2-why-4-desc'),
      figure: i18n.language === 'en' ? srcItem4FigureEn : srcItem4FigureJa,
    },
  ];

  return (
    <>
      <Swiper
        id="why"
        className={cx(styles.container, className)}
        activeSlideClassName={styles.active}
        indicatorClassName={styles.indicator}
        header={
          <h2>
            <Trans i18nKey="merchant-2-why-title" components={{ br: <br /> }} />
          </h2>
        }
        footer={
          <div className={styles.actions}>
            <a href="#case-studies">{t('case-studies')}</a>
          </div>
        }
      >
        {ITEM_DATA.map((item, index, arr) => (
          <ＷhyItem
            key={`why-${index}`}
            item={item}
            className={cx(index === 0 && styles.active)}
          />
        ))}
      </Swiper>
    </>
  );
};

export default Ｗhy;
