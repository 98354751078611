import { useWindowWidth } from '@react-hook/window-size';
import cx from 'classnames';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link, Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import Rellax from 'rellax';
import Layout from '../../components/Layout/Layout';
import { News } from '../../components/News/NewsRow';
import useImageQuery from '../../utils/use-image-query';
import styles from './merchant.module.scss';

import BankDirect from '../../components/BankDirect/BankDirect';
import ConsumerMobileApp from '../../components/ConsumerMobileApp/ConsumerMobileApp';
import FeaturedShops from '../../components/FeaturedShops/FeaturedShops';
import MerchantHelp from '../../components/Footer/MerchantHelp';
import Partner from '../../components/Footer/Partner';
import Hiroyuki from '../../components/Hiroyuki/Hiroyuki';
import AboutBNPL from '../../components/MerchantPage/AboutBNPL/AboutBNPL';
import Awards from '../../components/MerchantPage/Awards/Awards';
import CaseStudy from '../../components/MerchantPage/CaseStudy/CaseStudy';
import HightlightShops from '../../components/MerchantPage/HightlightShops/HightlightShops';
import Increase from '../../components/MerchantPage/Increase/Increase';
import ModalBNPL from '../../components/MerchantPage/ModalBNPL/ModalBNPL';
import Partners from '../../components/MerchantPage/Partners/Partners';
import Why from '../../components/MerchantPage/Ｗhy/Ｗhy';
import NewsPreview from '../../components/NewsPreview/NewsPreview';
import Overlay from '../../components/SideModal/Overlay';
import { Merchant, StripType } from '../../types';

interface Props {
  data: {
    locales: object;
    srcHeroOrderItemEn: any;
    srcHeroOrderItemJa: any;
    srcHeroScheduleEn: any;
    srcHeroScheduleJa: any;
    srcHeroThumbL: any;
    srcHeroThumbS: any;
    contentful: {
      merchantCollection: { items: Merchant[] };
      newsCollection: { items: News[] };
      stripCollection: { items: StripType[] };
    };
  };
}

export const merchantPageQuery = graphql`
  query Merchant2PageQuery($language: String!, $dev: Boolean!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contentful {
      stripCollection(
        order: sys_publishedAt_DESC
        limit: 10
        where: { domains_contains_some: "smartpay.co", active: true }
        preview: $dev
      ) {
        items {
          sys {
            id
          }
          title(locale: $language)
          link(locale: $language)
          type
          active(locale: $language)
          domains
          paths
          startsFrom
          endsOn
        }
      }
      merchantCollection(
        locale: $language
        where: { highlight: true }
        preview: $dev
      ) {
        items {
          sys {
            id
          }
          merchantId
          title
          titleKana
          description
          logo {
            url
            width
            height
          }
          slug
          url
        }
      }
      newsCollection(
        order: [date_DESC]
        locale: $language
        where: { slug_exists: true, cover_exists: true }
        preview: $dev
      ) {
        items {
          sys {
            id
          }
          content {
            json
          }
          categoryCollection(limit: 5) {
            items {
              title(locale: $language)
              slug
            }
          }
          merchantCollection(limit: 5) {
            items {
              title(locale: $language)
            }
          }
          publisherCollection(limit: 5) {
            items {
              title(locale: $language)
            }
          }
          slug
          title(locale: $language)
          excerpt(locale: $language)
          date
          cover {
            url
            width
            height
          }
          coverMobile {
            url
            width
            height
          }
          isHero
        }
      }
    }
  }
`;

const HeroFigure = () => {
  const { i18n } = useI18next();
  const winWidth = useWindowWidth();
  const isMobile = winWidth < 768;

  const [parallaxDisabled, setParallaxDisabled] = useState(true);
  const [heroStateThumbL, setHeroStateThumbL] = useState('inactive');
  const [heroStateThumbM, setHeroStateThumbM] = useState('inactive');
  const [heroStateThumbS, setHeroStateThumbS] = useState('inactive');
  const [heroStateOrderItem, setHeroStateOrderItem] = useState('inactive');

  const refParallaxThumbL = useRef<HTMLImageElement>(null);
  const refParallaxThumbM = useRef<HTMLImageElement>(null);
  const refParallaxThumbS = useRef<HTMLImageElement>(null);
  const refParallaxStatus = useRef<HTMLImageElement>(null);

  const refIntroAnchor = useRef<HTMLDivElement>(null);

  const {
    srcHeroOrderItemEn,
    srcHeroOrderItemJa,
    srcHeroScheduleEn,
    srcHeroScheduleJa,
    srcHeroThumbL,
    srcHeroThumbS,
  } = useImageQuery();

  const srcHeroOrderItem =
    i18n.language === 'en' ? srcHeroOrderItemEn : srcHeroOrderItemJa;
  const srcHeroThumbM =
    i18n.language === 'en' ? srcHeroScheduleEn : srcHeroScheduleJa;

  useLayoutEffect(() => {
    setTimeout(() => {
      setHeroStateThumbL('active');
    }, 1);
    setTimeout(() => {
      setHeroStateThumbM('active');
    }, 200);
    setTimeout(() => {
      setHeroStateThumbS('active');
    }, 400);
    setTimeout(() => {
      setHeroStateOrderItem('active');
    }, 600);
  }, []);

  useEffect(() => {
    const callback = (event: any) => {
      if (event.propertyName === 'opacity') {
        setParallaxDisabled(false);
      }
    };

    refIntroAnchor.current?.addEventListener('transitionend', callback);

    return () => {
      refIntroAnchor.current?.removeEventListener(
        'transitionend',
        callback,
        false
      );
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      if (refParallaxThumbL.current) {
        refParallaxThumbL.current.style.transform = '';
      }
      if (refParallaxThumbM.current) {
        refParallaxThumbM.current.style.transform = '';
      }
      if (refParallaxThumbS.current) {
        refParallaxThumbS.current.style.transform = '';
      }
      if (refParallaxStatus.current) {
        refParallaxStatus.current.style.transform = '';
      }
    }
  }, [isMobile]);

  useEffect(() => {
    if (!parallaxDisabled) {
      refParallaxThumbL.current &&
        new Rellax(refParallaxThumbL.current, { speed: 1 });
      refParallaxThumbM.current &&
        new Rellax(refParallaxThumbM.current, { speed: 3 });
      refParallaxThumbS.current &&
        new Rellax(refParallaxThumbS.current, { speed: 2 });
      refParallaxStatus.current &&
        new Rellax(refParallaxStatus.current, { speed: 4 });
    }
  }, [parallaxDisabled]);

  return (
    <div className={styles.heroFigure}>
      <div
        className={cx(
          styles.heroThumbL,
          styles[heroStateThumbL],
          !parallaxDisabled && styles.static
        )}
      >
        <div ref={refParallaxThumbL}>
          <GatsbyImage
            alt=""
            image={srcHeroThumbL.childImageSharp.gatsbyImageData}
          />
        </div>
      </div>
      <div
        className={cx(
          styles.heroThumbM,
          styles[heroStateThumbM],
          !parallaxDisabled && styles.static
        )}
      >
        <div
          ref={refParallaxThumbM}
          style={{ borderRadius: 26, overflow: 'hidden' }}
        >
          <GatsbyImage
            alt=""
            image={srcHeroThumbM.childImageSharp.gatsbyImageData}
          />
        </div>
      </div>
      <div
        className={cx(
          styles.heroThumbS,
          styles[heroStateThumbS],
          !parallaxDisabled && styles.static
        )}
      >
        <div ref={refParallaxThumbS}>
          <GatsbyImage
            alt=""
            image={srcHeroThumbS.childImageSharp.gatsbyImageData}
          />
        </div>
      </div>
      <div
        className={cx(
          styles.heroOrderItem,
          styles[heroStateOrderItem],
          !parallaxDisabled && styles.static
        )}
        ref={refIntroAnchor}
      >
        <div ref={refParallaxStatus}>
          <GatsbyImage
            alt=""
            image={srcHeroOrderItem.childImageSharp.gatsbyImageData}
            loading="eager"
          />
        </div>
      </div>
    </div>
  );
};

const MerchantPage: React.FC<Props> = ({ data }) => {
  const { t, originalPath, i18n } = useI18next();
  const [modalType, setModalType] = useState('japan-ec-market');
  const [showModal, setShowModal] = useState(false);
  const {
    contentful: {
      stripCollection: { items: strips },
    },
  } = data;

  const newsList = data.contentful.newsCollection.items.filter(
    (item) => item.slug
  );

  const highlightMerchantList = useMemo(
    () => data.contentful.merchantCollection.items.filter((item) => item.slug),
    [data.contentful.merchantCollection]
  );

  return (
    <>
      <Layout
        t={t}
        originalPath={originalPath}
        i18n={i18n}
        rootClassName={styles.index}
        audience="merchant"
        SEOProps={{
          meta: [
            {
              name: 'apple-itunes-app',
              content: 'app-id=1578056765',
            },
          ],
        }}
        strips={strips}
      >
        <section id="hero" className={styles.heroContainer}>
          <div className={styles.box}>
            <div className={cx(styles.content)}>
              <h1>
                {
                  <Trans
                    i18nKey="merchant-2-title"
                    components={{ span: <span /> }}
                  />
                }
              </h1>
              <p>
                {
                  <Trans
                    i18nKey="merchant-2-subtitle"
                    components={{ br: <br />, strong: <strong /> }}
                  />
                }
              </p>

              <div className={styles.heroActions}>
                <Link className={cx(styles.action)} to="/merchant/register">
                  {t('merchant-2-get-started')}
                </Link>
              </div>

              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={styles.heroArrow}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M25.7145 22.5976L20.5498 27.7622C20.2329 28.0792 19.7191 28.0792 19.4021 27.7622L14.2375 22.5976C13.9206 22.2807 13.9206 21.7668 14.2375 21.4499C14.5544 21.133 15.0683 21.133 15.3852 21.4499L19.1644 25.2292L19.1644 12.8115C19.1644 12.3633 19.5278 12 19.976 12C20.4242 12 20.7875 12.3633 20.7875 12.8115L20.7875 25.2292L24.5668 21.4499C24.8837 21.133 25.3975 21.133 25.7145 21.4499C26.0314 21.7669 26.0314 22.2807 25.7145 22.5976Z"
                  fill="#fff"
                />
              </svg>
            </div>
          </div>
          <HeroFigure />
        </section>
        <Awards />
        <Why />
        <HightlightShops merchants={highlightMerchantList} />
        <BankDirect />
        <Hiroyuki videoId="lMy2NAiVLqQ" title={t('hiroyuki-title-merchant')} />
        <Increase />
        <div id="slogan" className={styles.slogan}>
          <h2>
            {<Trans i18nKey="merchant-2-slogan" components={{ br: <br /> }} />}
          </h2>
        </div>
        <AboutBNPL
          showModalJapanEC={() => {
            setModalType('japan-ec-market');
            setShowModal(true);
          }}
          showModalBNPLCompare={() => {
            setModalType('bnpl-compare');
            setShowModal(true);
          }}
          showModalSecurity={() => {
            setModalType('security');
            setShowModal(true);
          }}
        />
        <CaseStudy />
        <Partners />
        {/* <Try /> */}
        <FeaturedShops className={styles.featuredShops} />
        <ConsumerMobileApp className={styles.consumerMobileApp} />
        <NewsPreview data={newsList} />
        <Partner t={t} />
        <MerchantHelp t={t} />
      </Layout>
      <Overlay
        active={showModal}
        close={() => {
          setShowModal(false);
        }}
      />
      <ModalBNPL
        active={showModal}
        type={modalType}
        close={() => {
          setShowModal(false);
        }}
      />
    </>
  );
};

export default MerchantPage;
